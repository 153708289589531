import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill theme CSS
import './BlogEditor.css'; // Import the CSS
import CommentsPopup from './CommentsPopup';
import { addBlog, uploadImages } from '../services/blogs';
import getTypesService from '../services/getTypes';

const BlogEditor = (props) => {
  const blog = props.blog;
  const { onCancel, password } = props;
  const blogId = blog._id || false;
  const navigate = useNavigate();
  const [types, setTypes] = useState();

  const [blogName, setBlogName] = useState(blog.blogName || '');
  const [blogContent, setBlogContent] = useState(blog.blogContent.replaceAll('<p><br></p><p><br></p>',"<p><br></p>") || '');
  const [blogUrl, setBlogUrl] = useState(blog.blogUrl || '');
  const [metaTitle, setMetaTitle] = useState(blog.metaTitle || '');
  const [metaDescription, setMetaDescription] = useState(blog.metaDescription || '');
  const [metaKeywords, setMetaKeywords] = useState(blog.metaKeywords || '');
  const [schema, setSchema] = useState(JSON.stringify(blog.schema) || "");
  const [blogCategories, setBlogCategories] = useState(blog.blogCategories || []);
  const [images, setImages] = useState(blog.images || []);
  const [comments, setComments] = useState(blog.comments || {});
  const [isPreview, setIsPreview] = useState(false);
  const [selectedComments, setSelectedComments] = useState(null);
  const quillRef = useRef(null);

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          const altText = prompt('Enter alt text for the image'); // Prompt for alt text

          const quill = quillRef.current?.getEditor();
          const range = quill?.getSelection(); // Get the current selection

          if (range) {
            const imageTag = `<img src="${imageUrl}" alt="${altText || ''}" />`; // Insert image with alt text
            quill.clipboard.dangerouslyPasteHTML(range.index, imageTag); // Insert HTML directly at the current selection
          }
        };
        reader.readAsDataURL(file); // Convert image file to a data URL
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'], // Allow adding images through the toolbar
        [{ color: [] }, { background: [] }], // Text color and background
        [{ align: [] }, { direction: [] }], // Text alignment
      ],
      handlers: {
        image: selectLocalImage, // Custom image handler
      },
    },
  }), []);

  const extractImagesFromContent = useCallback((content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const imgTags = div.querySelectorAll('img');
    return Array.from(imgTags).map((img) => img.src);
  }, []);

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleSubmit = async () => {
    try {
      const extractedImages = extractImagesFromContent(blogContent);

      let updatedContent = blogContent;
      if (extractedImages.length > 0) {
        var imagesResponse = await uploadImages(password, extractedImages, blogUrl);
        if (imagesResponse.images) {
          setImages(imagesResponse.images);

          // Replace local image URLs with S3 URLs in the blog content
          extractedImages.forEach((imgSrc, index) => {
            const s3Url = imagesResponse.images[index];
            updatedContent = updatedContent.replace(imgSrc, s3Url);
          });
        } else {
          alert(imagesResponse.error || "Couldn't upload images.");
          return;
        }
      } else {
        setImages(['/empty.png']);
      }

      const formData = {
        blogId,
        blogName,
        blogContent: updatedContent, // Use updated content with S3 URLs
        blogUrl,
        blogCategories,
        metaTitle,
        metaDescription,
        metaKeywords,
        schema: 
        {
          "@context": "https://schema.org/",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://www.beatmyinsurance.com/blogs/${blogUrl || ""}`
          },
          "headline": `${blogName || ""}`,
          "description": `${metaDescription || ""}`,
          "image": {
            "@type": "ImageObject",
            "url": `${images.length > 0 ?images[0] : "https://www.beatmyinsurance.com/logo.png"}`
    
          },
          "author": {
            "@type": "Person",
            "name": "Sara Ali"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Beat My Insurance",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.beatmyinsurance.com/logo.png",
              "width": "272",
              "height": "40"
            }
          },
          "datePublished": "2024-11-04",
          "dateModified": `${new Date}`
        }
        ,
        images: imagesResponse ? imagesResponse.images : ['/empty.png'],
        comments,
      };

      const response = await addBlog(formData, password);
      if (response.message) {
        alert(response.message);
        onCancel();
      } else {
        alert(response.error || 'Something went wrong!');
      }
    } catch (error) {
      console.error('Error saving blog:', error);
    }
  };

  const handleViewComments = () => setSelectedComments(comments);

  const handleCategoryChange = (category, setter) => {
    setter((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((item) => item !== category)
        : [...prevCategories, category]
    );
  };

  return (
    <div className="blog-editor-container">
      <input
        type="text"
        placeholder="Blog Title"
        className="blog-title-input"
        value={blogName}
        onChange={(e) => setBlogName(e.target.value)}
      />

      <input
        type="text"
        className="blog-url-input"
        placeholder="Blog Url"
        value={blogUrl}
        onChange={(e) => setBlogUrl(e.target.value)}
      />

      <input
        type="text"
        className="blog-meta-title"
        placeholder="Meta Title"
        value={metaTitle}
        onChange={(e) => setMetaTitle(e.target.value)}
      />
      <input
        type="text"
        className="blog-meta-description"
        placeholder="Meta Description"
        value={metaDescription}
        onChange={(e) => setMetaDescription(e.target.value)}
      />
      <input
        type="text"
        className="blog-meta-keywords"
        placeholder="Meta Keywords"
        value={metaKeywords}
        onChange={(e) => setMetaKeywords(e.target.value)}
      />
      

      <ReactQuill
        theme="snow"
        value={blogContent}
        onChange={setBlogContent} // Ensure correct state update
        placeholder="Write your blog content here..."
        modules={modules}
        ref={quillRef}
      />
      {types &&
        types.map((type) => (
          <div key={type} className="admin-dashboard-checkbox">
            <input
              type="checkbox"
              id={`add-category-${type}`}
              value={type}
              checked={blogCategories.includes(type)}
              onChange={() => handleCategoryChange(type, setBlogCategories)}
            />
            <label htmlFor={`add-category-${type}`}>{type}</label>
          </div>
        ))}

      <div className="button-group">
        <button onClick={() => setIsPreview(!isPreview)}>
          {isPreview ? 'Edit' : 'Preview'}
        </button>
        <button onClick={handleViewComments}>View Comments</button>
        <button onClick={onCancel}>Cancel</button>
        <button onClick={handleSubmit}>Save</button>
      </div>

      {isPreview && (
        <div className="blog-preview">
          <h1>{blogName}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: blogContent }}
            className="preview-content"
          />
        </div>
      )}

      {selectedComments && (
        <CommentsPopup
          comments={selectedComments}
          onClose={() => setSelectedComments(null)}
        />
      )}
    </div>
  );
};

export default BlogEditor;
