import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const fetchAllBlogs = async () => {
    const response = await fetch(`${API_URL}/fetch-blogs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }});
    return response.json();
};

const addBlog = async (formData, password) => {
    const response = await fetch(`${API_URL}/add-blog/${password}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    });
    return response.json();
};

const deleteBlog = async (blogId, password) => {
    const response = await fetch(`${API_URL}/delete-blog/${password}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({blogId : blogId}),
    });
    return response.json();
};

const uploadImages = async (password, images, blogUrl) => {
    console.log('Uploading images...');
    
    const formData = new FormData();
  
    // Step 1: Filter base64 images (skip valid S3 URLs)
    const base64Images = images.filter(image => !isS3Url(image));
  
    if (base64Images.length === 0){
        return {images: images};
    }
    // Step 2: Append base64 images to FormData
    base64Images.forEach((image, index) => {
      const blob = base64ToBlob(image); // Convert base64 to Blob
      formData.append('images', blob, `image-${index}.jpg`);
    });
  
    formData.append('blogUrl', blogUrl);
  
    try {
      // Step 3: Upload only base64 images to API
      const response = await fetch(`${API_URL}/upload-blog-images/${password}`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Upload failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Upload response:', data);
  
      // Step 4: Replace base64 images with S3 URLs in the original array
      const updatedImages = replaceImagesWithS3Urls(images, data.images);
      console.log('Updated Images:', updatedImages);
  
      return { images: updatedImages };
    } catch (error) {
      console.error('Error uploading images:', error);
      return { error: 'Image upload failed' };
    }
  };
  
  // Helper function to check if a URL is an S3 image URL
  const isS3Url = (url) => {
    console.log(/^https:\/\/.*\.s3\.amazonaws\.com\/.*$/.test(url));
    return /^https:\/\/.*\.s3\.amazonaws\.com\/.*$/.test(url);
};
  
  // Helper function to replace base64 images with S3 URLs in the original array
  const replaceImagesWithS3Urls = (originalImages, s3Urls) => {
    let s3Index = 0; // Track the index of uploaded S3 URLs
  
    return originalImages.map(image => {
      if (isS3Url(image)) {
        return image; // Keep existing S3 URLs
      }
      return s3Urls[s3Index++] || '/empty.png'; // Replace base64 with uploaded S3 URL
    });
  };
  
  // Helper function to convert Base64 string to Blob
  const base64ToBlob = (base64String) => {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([byteArray], { type: mimeString });
  };
  
  
  
  

export {fetchAllBlogs, addBlog, deleteBlog, uploadImages};
