import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const positionsService = {
    getPositions: async() =>{
      try {
        const response = await fetch(`${API_URL}/positions`); 
        return response.json(); 
      } catch (error) {
        console.error('Failed to fetch positions:', error.message);
        throw new Error('Failed to fetch positions');
      }
    },
    updatePositions: async(positions)=> {
        try {
            const response = await fetch(`${API_URL}/updatePositions`,{
                method:"PUT",
                headers:{
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify(positions),
            }); 
            return response.json(); 
          } catch (error) {
            console.error('Failed to update positions:', error.message);
            throw new Error('Failed to update positions');
          }
    }
  };
  
  export default positionsService;
  