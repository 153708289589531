import React, { useState } from 'react';
import { fetchAllChats } from '../services/chats'; 
import './Chats.css';

const Chats = () => {
    const [passwordPop, setPasswordPop] = useState(true); 
    const [password, setPassword] = useState(""); 
    const [chats, setChats] = useState([]); 
    const [selectedChat, setSelectedChat] = useState(null); // To store the selected chat
    const [error, setError] = useState(""); 

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetchAllChats(password);
            const fetchedChats = response.chats;

            const chatsArray = Array.isArray(fetchedChats) ? fetchedChats : Object.values(fetchedChats);

            if (chatsArray) {
                setChats(chatsArray);
                setPasswordPop(false); 
                setError(""); 
                console.log(chatsArray);
            }
        } catch (err) {
            setError("Invalid password or unable to fetch chats.");
        }
    };

    // Function to handle when a chat is clicked
    const handleChatClick = (chat) => {
        setSelectedChat(chat); // Set the selected chat to display messages
    };

    const getUserEmail = (chat, userId) => {
        return chat.userEmails[userId] || "Unknown User";
    };

    const getEmailsFromChat = (chat) => {
        const userIds = chat.chatKey.split("_x_");
        const userEmails = chat.userEmails; // Assuming the emails are stored in `userEmails` object within each chat
    
        return userIds.map(userId => userEmails[userId] || "NotFound");
    };
    

    return (
        <div className='admin-chats-page'>
            {passwordPop && (
                <div className='password-popup'>
                    <h2>Enter Master Password</h2>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Master Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}

            {!passwordPop && (
                <div className='chats-section'>
                    <div className='chats-list'>
                        <h2>All Chats</h2>
                        {chats.length > 0 ? (
                            chats.map((chat) => (
                                <div
                                    key={chat._id}
                                    className={`chat-item ${selectedChat && selectedChat._id === chat._id ? 'active' : ''}`}
                                    onClick={() => handleChatClick(chat)} // Make chats clickable
                                >
                                    <p>{getEmailsFromChat(chat)[0]} and {getEmailsFromChat(chat)[1]}</p>
                                    <p>Created At: {new Date(chat.createdAt).toLocaleString()}</p>
                                    <p>Messages: {chat.allMessages.length}</p>
                                    <hr />
                                </div>
                            ))
                        ) : (
                            <p>No chats available</p>
                        )}
                    </div>

                    <div className='chat-messages'>
                        {selectedChat ? (
                            <div>
                                <h2>Messages between {getEmailsFromChat(selectedChat)[0]} and {getEmailsFromChat(selectedChat)[1]}</h2>
                                <div className="messages-container">
                                    {selectedChat.allMessages.map((message, index) => (
                                        <div key={index} className="message-item">
                                            <p>
                                                {getUserEmail(selectedChat, message[0])}: {message[1]}
                                            </p>
                                            <small>{new Date(message[2]).toLocaleTimeString()}</small>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>Select a chat to view messages</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chats;
