import React, { useState } from 'react';
import { fetchAllUsers, blockUser } from '../services/user';
import './User.css';

const Users = () => {
    const [passwordPop, setPasswordPop] = useState(true);
    const [password, setPassword] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [error, setError] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchDate, setSearchDate] = useState('');

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetchAllUsers(password);
            const fetchedUsers = response.users;
            const usersArray = Array.isArray(fetchedUsers) ? fetchedUsers : Object.values(fetchedUsers);

            if (usersArray) {
                setUsers(usersArray);
                setPasswordPop(false);
                setError('');
            }
        } catch (err) {
            setError('Invalid password or unable to fetch users.');
        }
    };

    const handleUserClick = (user) => {
        selectedUser ? setSelectedUser(null) : setSelectedUser(user);
        
    };

    const handleBlockClick = async (userId) => {
        try {
            const response = await blockUser({ userId, password });
            if (response.message) {
                alert(response.message);
            } else {
                alert(response.error || 'Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong!');
        }
    };

    const filteredUsers = users.filter((user) =>
        user.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
        user.joiningDate.includes(searchDate)
    );

    return (
        <div className="admin-users-page">
            {passwordPop && (
                <div className="password-popup">
                    <h2>Enter Master Password</h2>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Master Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}

            {users.length > 0 ? (
                <>
                    <h1>All users</h1>

                    {/* Search Filters */}
                    <div className="search-filters">
                        <input
                            type="text"
                            placeholder="Search by Email"
                            value={searchEmail}
                            onChange={(e) => setSearchEmail(e.target.value)}
                        />
                        <input
                            type="date"
                            placeholder="Search by Date"
                            value={searchDate}
                            onChange={(e) => setSearchDate(e.target.value)}
                        />
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Joined On</th>
                                <th>Mobile</th>
                                <th>Current/previous paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user) => (
                                <tr key={user._id} onClick={() => handleUserClick(user)}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.joiningDate}</td>
                                    <td>{user.mobile}</td>
                                    <td>{user.subscription ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>It's empty</p>
            )}

            {selectedUser && (
                <div className="user-details-adminuser">
                    <button onClick={() => setSelectedUser(null)}>Close</button>
                    <button onClick={() => handleBlockClick(selectedUser._id)}>Blacklist user</button>
                    <img src={selectedUser.image} alt="profile" />
                    <p>Bio: {selectedUser.bio}</p>
                    <p>Joined On: {selectedUser.joiningDate}</p>
                </div>
            )}
        </div>
    );
};

export default Users;
