import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const getTypesService = {
    async getTypes() {
      try {
        const response = await fetch(`${API_URL}/types`); 
        const data = await response.json();
        return data.types; 
      } catch (error) {
        console.error('Failed to fetch types:', error.message);
        throw new Error('Failed to fetch types');
      }
    },
  };
  
  export default getTypesService;
  