import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const discountService = {
  getDiscounts: async () => {
    try {
      const response = await fetch(`${API_URL}/discounts`);
      const data = await response.json();
      return data.discounts;
    } catch (error) {
      console.error('Failed to fetch discounts:', error.message);
      throw new Error('Failed to fetch discounts');
    }
  },

  

  deleteDiscount: async (id) => {
    console.log(id);
    try {
      await fetch(`${API_URL}/discounts/${id}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Failed to delete discount:', error.message);
      throw new Error('Failed to delete discount');
    }
  },

  addDiscount: async (newDiscount) => {
    try {
      const response = await fetch(`${API_URL}/discounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newDiscount),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to add discount:', error.message);
      throw new Error('Failed to add discount');
    }
  },
};

export default discountService;
