import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const fetchContactMessages = async () => {
    const response = await fetch(`${API_URL}/contact-message`); // Ensure this URL is correct
    if (!response.ok) {
        throw new Error('Failed to fetch contact messages');
    }
    const data = await response.json();
    return data;
};

export {fetchContactMessages};
