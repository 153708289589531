import React, { useState } from 'react';
import { fetchAllApplications, verifySeller } from '../services/sellers';
import "./SellersVerify.css"; 

const SellerVerify = () => {
    const [passwordPop, setPasswordPop] = useState(true); 
    const [password, setPassword] = useState(""); 
    const [applications, setApplications] = useState([]); 
    const [selectedApplication, setSelectedApplication] = useState(null); 
    const [error, setError] = useState(""); 

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetchAllApplications(password);
            const fetchedApplications = response.data;

            const applicationsArray = Array.isArray(fetchedApplications) ? fetchedApplications : Object.values(fetchedApplications);

            if (applicationsArray) {
                setApplications(applicationsArray);
                setPasswordPop(false); 
                setError(""); 
                console.log(applicationsArray);
            }
        } catch (err) {
            setError("Invalid password or unable to fetch applications.");
        }
    };

    const handleApplicationClick = (application) => {
        setSelectedApplication(application); 
    };

    const handleApprove = async() => {
        const formData = {
            application:selectedApplication,
            approved:true,
            password: password
        }
        const response = await verifySeller(formData);
        if (response.message){
            alert(response.message);
        }
        else{
            alert(response.error || "Something went wrong");
        }
        
    };

    const handleReject = async() => {
        const formData = {
            application: selectedApplication,
            approved:false,
            password: password
        }
        const response = await verifySeller(formData);
        if (response.message){
            alert(response.message);
        }
        else{
            alert(response.error || "Something went wrong");
        }
    };

    return (
        <div className='admin-sellers-verify-page'>
            {passwordPop && (
                <div className='password-popup'>
                    <h2>Enter Master Password</h2>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Master Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}

            {!passwordPop && (
                <div className="applications-table">
                    <h2>Applications</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>License Number</th>
                                <th>Company Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {applications.map((application) => (
                                <tr key={application._id.$oid} onClick={() => handleApplicationClick(application)}>
                                    <td>{application.email}</td>
                                    <td>{application.licenseNumber}</td>
                                    <td>{application.companyName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {selectedApplication && (
                        <div className="selected-application">
                            <button onClick={()=>setSelectedApplication(null)}>close</button>
                            <h3>Application Details</h3>
                            <p><strong>Email:</strong> {selectedApplication.email}</p>
                            <p><strong>License Number:</strong> {selectedApplication.licenseNumber}</p>
                            <p><strong>Company Name:</strong> {selectedApplication.companyName}</p>
                            <p><strong>Licensed Since:</strong> {selectedApplication.licensedSince}</p>
                            <p><strong>User ID:</strong> {selectedApplication.userId}</p>
                            <p><strong>Token:</strong> {selectedApplication.token}</p>

                            <div className="action-buttons">
                                <button onClick={handleApprove}>Approve</button>
                                <button onClick={handleReject}>Reject</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SellerVerify;
