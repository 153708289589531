import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const fetchAllChats = async (password) => {
    const response = await fetch(`${API_URL}/admin-fetch-chats/${password}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }});
    const data = await response.json();
    return data;
};

export {fetchAllChats};
