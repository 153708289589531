import config from "../utils/ApiUrl";
const API_URL = config.API_URL;


const userLogin = async (formdata) => {
    try {
        const response = await fetch(`${API_URL}/admin-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formdata),
        });
        return response.json();
      } catch (error) {
        console.error('Failed to Login:', error.message);
        throw new Error('Failed to Login');
      }
};

const fetchAllUsers = async(password) => {
  const response = await fetch(`${API_URL}/admin-fetch-users/${password}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }});
  const data = await response.json();
  return data;
};

const blockUser = async(formdata) => {
  const response = await fetch(`${API_URL}/admin-user-block`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata)
    });
  const data = await response.json();
  return data;
};

export {userLogin, fetchAllUsers, blockUser };
