import React, { useState } from "react";
import {sendSmsToAll} from '../services/mobileService';

const SmsComponent = () => {
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordPop, setPasswordPop] = useState(true);
  const handleSubmit = async(e) => {
    e.preventDefault();
    const response = await sendSmsToAll(message, password);
    if(response.message){
        alert('Successfully sent messages!');
    }
  };

  const handlePasswordSubmit = (e) =>{
    e.preventDefault(); 
    setPasswordPop(false);
  }

  return (
    <>
      {passwordPop && (
        <div className="password-popup">
          <h2>Enter Master Password</h2>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="Master Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label>Message</label>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter message here"
        />
        <button type="submit">Send</button>
      </form>
    </>
  );
};

export default SmsComponent;
