// ExcelUploader.js
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { uploadStats } from '../services/calculator';

const ExcelUploader = () => {
    const [statsData, setStatsData] = useState();
    const [passwordPop, setPasswordPop] = useState(true); 
    const [password, setPassword] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const xlData = new Uint8Array(event.target.result);
      const workbook = XLSX.read(xlData, { type: 'array' });

      // Assuming the data is in the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      console.log(jsonData);
      if (jsonData){
        setStatsData(jsonData);
      }
      else{
        alert('Improper file or corrupted data.');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSave = async () =>{
    setLoading(true);
    try {
        const response = await uploadStats(password,statsData);
        if(response){
            setLoading(false);
            alert('All data uploaded successfully!');
        }
    }
    catch{
        alert('something went wrong!');
    }
  };

  const handlePasswordSubmit = () =>{
    setPasswordPop(false);
  };

  return (
    <div>
        {passwordPop && (
                <div className='password-popup'>
                    <h2>Enter Master Password</h2>
                    <form onSubmit={handlePasswordSubmit}>
                        <input
                            type="password"
                            placeholder="Master Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}
      {!passwordPop && !loading && (<>
        <h2>Upload Excel File</h2>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        <button onClick={handleSave} >Save</button>
      </>)}
      {loading && (<>
      <img src='/loading.gif' alt='loaading'/>
      </>)}
    </div>
  );
};

export default ExcelUploader;
