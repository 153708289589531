import React, { useState, useEffect } from 'react';
import getAdminFieldsService from '../services/adminFields';
import positionsService from '../services/indexing';

const Indexing = () => {
    const [fields, setFields] = useState([]);
    const [positions, setPositions] = useState([]);
    
    const [edit, setEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [insertPosition, setInsertPosition] = useState(0);

    // Fetch fields from the service
    const fetchFields = async () => {
        try {
            const fetchedFields = await getAdminFieldsService.getAdminFields();
            setFields(fetchedFields);
        } catch (error) {
            console.error("Failed to fetch fields:", error.message);
        }
    };

    const fetchPositions = async () => {
        try {
            const response = await positionsService.getPositions();
            const fetchedPositions = response.positions;
            setPositions(fetchedPositions);
            console.log(fetchedPositions);
        } catch (error) {
            console.error("Failed to fetch positions:", error.message);
        }
    };
    

    useEffect(() => {
        fetchFields();
        fetchPositions();
    }, []);

    const handleRowClick = (id) => {
        setSelectedId(id);  // Set the selectedId to the position ID
        setEdit(!edit);  // Toggle the edit state
    };

    // Handle updating the positions array
    const handleUpdate = async() => {
        const updatedPositions = [...positions];
        
        // Remove the selectedId from its current position
        const currentIndex = updatedPositions.indexOf(selectedId);
        if (currentIndex > -1) {
            updatedPositions.splice(currentIndex, 1);
        }
    
        // Insert selectedId before the insertPosition
        const insertIndex = updatedPositions.indexOf(parseInt(insertPosition));
        if (insertIndex !== -1) {  // Ensure 0 is a valid index
            updatedPositions.splice(insertIndex, 0, selectedId);
        }
    
        
        try{
            const response = await positionsService.updatePositions(updatedPositions);
            if(response.message){
                alert(response.message);
                setPositions(updatedPositions);
            }
            else{
                alert(response.error || "Something went wrong");
            }
        }catch(error){
            alert(error || "Something went wrong");
        }
        setEdit(false);  // Close the edit form after updating
        console.log(updatedPositions);  // Make sure you're logging the updated array
    };
    

    return (
        <div className="indexing-tab">
            <table>
                <thead>
                    <tr>
                        <th>SN</th>
                        <th>Name</th>
                        <th>Id</th>
                    </tr>
                </thead>
                <tbody>
                    {fields && positions && (
                        positions.map((position) => (
                             (fields[position] !== undefined) ? (
                            <React.Fragment key={position}>
                                <tr onClick={() => handleRowClick(position)}>
                                    <td>{positions.indexOf(position)}</td>
                                    <td>{fields[position] !== undefined ? fields[position].name : 'Loading...'}</td>
                                    <td>{position}</td>
                                </tr>
                                {edit && selectedId === position && (
                                    <tr>
                                        <td>Selected Id : {selectedId}</td>
                                        <td>
                                            Insert before:
                                            <select onChange={(e) => setInsertPosition(e.target.value)}>
                                                <option value={0}>Select</option>
                                                {positions.map((id) => (
                                                    fields[id] !== undefined ?
                                                    (
                                                    <option key={id} value={id}>
                                                        {fields[id].name}
                                                    </option>
                                                    )
                                                    :
                                                    (
                                                        <></>
                                                    )
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <button onClick={handleUpdate}>Update</button>
                                        </td>
                                    </tr>
                                ) }
                            </React.Fragment>
                            ) : (<></>)
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Indexing;
