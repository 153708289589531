import config from "../utils/ApiUrl";
const API_URL = config.API_URL;


const postDetailsProService = {
  getpostDetailsPro: async () => {
    try {
      const response = await fetch(`${API_URL}/postDetailsPro`);
      const data = await response.json();
      console.log(data);
      return data.types;
    } catch (error) {
      console.error('Failed to fetch fields:', error.message);
      throw new Error('Failed to fetch fields');
    }
  },

  updatepostDetailsPro: async (id, updatedField) => {
    try {
      const response = await fetch(`${API_URL}/postDetailsPro/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedField),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to update field:', error.message);
      throw new Error('Failed to update field');
    }
  },

  deletepostDetailsPro: async (id) => {
    try {
      await fetch(`${API_URL}/postDetailsPro/${id}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Failed to delete field:', error.message);
      throw new Error('Failed to delete field');
    }
  },

  addpostDetailsPro: async (newField) => {
    try {
      const response = await fetch(`${API_URL}/postDetailsPro`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newField),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to add field:', error.message);
      throw new Error('Failed to add field');
    }
  },
};

export default postDetailsProService;
