import React, { useEffect, useState } from 'react';
import { fetchSupportMessages } from '../services/support';
import './Support.css';

const SupportMessages = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getMessages = async () => {
            try {
                const data = await fetchSupportMessages();
                setMessages(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        getMessages();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="support-messages-container">
            <h1>Support Messages</h1>
            <table className="support-messages-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Topic</th>
                        <th>Message</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => (
                        <tr key={message._id}>
                            <td>{message.firstName}</td>
                            <td>{message.lastName}</td>
                            <td>{message.email}</td>
                            <td>{message.topic}</td>
                            <td>{message.message}</td>
                            <td>{new Date(message.createdAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SupportMessages;
