import React, { useEffect, useState } from "react";
import discountService from "../services/discounts";

const Discounts = () => {
  const [discounts, setDiscounts] = useState();
  const [discountName, setDiscountName] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [discountId, setDiscountId] = useState();

  useEffect(() => {
    fetchDiscounts();
  },[]);

  const fetchDiscounts = async () => {
    try {
      const discounts = await discountService.getDiscounts();
      setDiscounts(discounts);

    } catch (error) {
      console.log(error);
    }
  };

  const handleValueChange = (value) => {
    if (Number(value) === NaN) {
      alert("Please enter proper number");
      setDiscountValue(0);
    } else {
      setDiscountValue(value);
    }
  };

  const handleAdd = async () => {
    if (discountName && discountValue) {
      try {
        const newDiscount = {
          name: discountName,
          value: discountValue,
        };
        const response = await discountService.addDiscount(newDiscount);
        fetchDiscounts();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  };
  const handleDelete = async(id) =>{
    try{
        const response = await discountService.deleteDiscount(id);
        fetchDiscounts();
    }catch(error){
        console.log(error);
        alert('something went wrong while deleting',error);
    }
  }

  return (
    <div>
      <div>
        <input
          type="text"
          value={discountName}
          placeholder="enter new promocode here"
          onChange={(e) => setDiscountName(e.target.value)}
        />
        <input
          type="text"
          value={discountValue}
          placeholder="enter amount to be deducted"
          onChange={(e) => handleValueChange(e.target.value)}
        />
        <button onClick={() => handleAdd()}>Add Promocode</button>
      </div>
      {discounts && (
        <div>
            {discounts.map((discount) => (
                <div key={discount._id}>
                <p>{discount.name} :{discount.value} <button  onClick={() => handleDelete(discount._id)}>Delete</button> </p>
              
                </div>
            ))}
        </div> 
      )}
    </div>
  );
};

export default Discounts;
