// CommentsPopup.js
import React from 'react';
import './CommentsPopup.css';

const CommentsPopup = ({ comments, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Comments</h2>
        <ul>
          {Object.entries(comments).map(([name, { message, read }], index) => (
            <li key={index}>
              <strong>{name}:</strong> {message} {read ? '✔️' : '❌'}
            </li>
          ))}
        </ul>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CommentsPopup;
