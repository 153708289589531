import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminDashboard from './components/Admin';
import ContactMessages from './components/Contact';
import SupportMessages from './components/Support';
import Discounts from './components/Discounts';
import Login from './components/Home';
import Chats from './components/Chats';
import Users from './components/Users';
import SellerVerify from './components/SellersVerify';
import Indexing from './components/Indexing';
import BlogEditor from './components/BlogEditor';
import Blogs from './components/Blogs';
import ExcelUploader from './components/Calculator';
import SmsComponent from './components/Sms';
import RestrictedFields from './components/RestrictedFields';

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path='/' element={<Login/> }/>
          <Route path='/home' element={<Login/> }/>
          <Route path='/admin' element={<AdminDashboard/>} />
          <Route path='/discounts' element={<Discounts/>} />
          <Route path='/contact-message' element={<ContactMessages/>} />
          <Route path='/support-message' element={<SupportMessages/>} />
          <Route path='/chats-inspect' element={<Chats/>}/>
          <Route path='/users-inspect' element= {<Users/>}/>
          <Route path='/sellers-verify' element= {<SellerVerify/>}/>
          <Route path='/indexing' element= {<Indexing/>}/>
          <Route path='/blogs/:blogUrl' element = {<BlogEditor/>}/>
          <Route path='/blogs' element = {<Blogs />}/>
          <Route path='/calculator' element = {<ExcelUploader />}/>
          <Route path='/sms-sender' element = {<SmsComponent />} />
          <Route path='/restrict-fields' element = {<RestrictedFields/>}/>
      </Routes>
    </Router>
  );
}

export default App;
