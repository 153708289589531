import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const sendSmsToAll = async (message, password) => {
    const response = await fetch(`${API_URL}/send-sms-to-all/${password}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({message: message}),
      });
      const data = await response.json();
      return data;
    };
export {sendSmsToAll};