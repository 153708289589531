import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const fetchAllApplications = async (password) => {
    const response = await fetch(`${API_URL}/admin-fetch-seller-applications/${password}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }});
    const data = await response.json();
    return data;
};

const verifySeller = async (formData) =>{
    const response = await fetch(`${API_URL}/admin-verify-seller`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData)
    });
    const data = await response.json();
    return data;
}

export {fetchAllApplications, verifySeller};
