import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const CHUNK_SIZE = 100; // Adjust this based on the size that works (e.g., 100 objects per chunk)

const uploadStatsChunk = async (password, chunk) => {
  const response = await fetch(`${API_URL}/admin-upload-stats/${password}`, {
    method: "POST",
    body: JSON.stringify(chunk),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to upload chunk: ${response.statusText}`);
  }
  return response.json();
};

const uploadStats = async (password, data) => {
  const totalChunks = Math.ceil(data.length / CHUNK_SIZE);

  for (let i = 0; i < totalChunks; i++) {
    const chunk = data.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
    console.log(`Uploading chunk ${i + 1} of ${totalChunks}...`);

    try {
      await uploadStatsChunk(password, chunk);
    } catch (err) {
      console.error(`Error uploading chunk ${i + 1}:`, err);
      alert(`Upload failed for chunk ${i + 1}. Please try again.`);
      return; // Stop the upload if a chunk fails
    }
  }
  
  return true;
};

export { uploadStats };
