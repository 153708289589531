import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const restrictedFieldsService = {
  getFields: async () => {
    try {
      const response = await fetch(`${API_URL}/restricted-fields`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch fields:', error.message);
      throw new Error('Failed to fetch fields');
    }
  },
  

  deleteField: async (formdata) => {
    console.log(formdata.id);
    try {
      await fetch(`${API_URL}/delete-rectricted-field/${formdata.id}/${formdata.password}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Failed to delete Field:', error.message);
      throw new Error('Failed to delete Field');
    }
  },

  addField: async (formdata) => {
    try {
      const response = await fetch(`${API_URL}/add-restricted-field`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formdata),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to add field:', error.message);
      throw new Error('Failed to add field');
    }
  },
};

export default restrictedFieldsService;
