import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import './Home.css';
import { userLogin } from '../services/user';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  

  const navigate = useNavigate();

  const handleLogin = async() => {
    try{
        const response = await userLogin({email,password});
        console.log(response);
        if (response.BMI_ADMIN) {
            localStorage.setItem("BMI_ADMIN", JSON.stringify(response));
            localStorage.setItem("isBMIAdminLogged", "true");
            alert("Login Successful");
    
            navigate("/admin");
          } else {
            alert(response.message);
          }
    }catch(error){
        alert(error);
    }
    
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
          />
        </div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
