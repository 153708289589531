// BlogManager.js
import React, { useState, useEffect } from "react";
import BlogEditor from "./BlogEditor";
import CommentsPopup from "./CommentsPopup";
import "./Blogs.css";
import { fetchAllBlogs, deleteBlog } from "../services/blogs";

/*const exampleBlogs = [
  {
    blogName: "Big Deals on Insurance",
    blogContent:
      "<h1>Introduction</h1><p>Everyone needs great deals to save money...</p>",
    blogUrl: "big-deals",
    images: ["https://via.placeholder.com/100"],
    comments: {
      Alice: { message: "Amazing post!", read: false },
      Bob: { message: "Very helpful, thanks!", read: true },
    },
  },
  {
    blogName: "Insurance Tips for 2024",
    blogContent:
      "<h1>Plan Smart</h1><p>Here are some smart ways to get the best insurance coverage...</p>",
    blogUrl: "insurance-tips-2024",
    images: ["https://via.placeholder.com/100"],
    comments: {
      Charlie: { message: "Nice tips!", read: true },
    },
  },
]; */

const Blogs = () => {
  const [passwordPop, setPasswordPop] = useState(true);
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState(null); // For editing
  const [isCreating, setIsCreating] = useState(false); // Create mode
  const [searchQuery, setSearchQuery] = useState(""); // Search state
  const [selectedComments, setSelectedComments] = useState(null); // Comments for popup


  const fetchBlogs = async() =>{
    try {
        const response = await fetchAllBlogs();
        const fetchedBlogs = response.blogs;
  
        const blogsArray = Array.isArray(fetchedBlogs)
          ? fetchedBlogs.reverse()
          : Object.values(fetchedBlogs);
  
        if (blogsArray) {
          setBlogs(blogsArray);
          setPasswordPop(false);
          setError("");
          console.log(blogsArray);
        }
      } catch (err) {
        setError("Invalid password or unable to fetch blogs.");
      }
  }
  const handlePasswordSubmit =  (e) => {
    e.preventDefault();
    fetchBlogs();
  };

  const handleEdit = (blog) => {
    setCurrentBlog(blog);
    setIsCreating(true);
  };

  const handleCreateNew = () => {
    setCurrentBlog({
      blogName: "",
      blogContent: "",
      blogUrl: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      images: "",
      comments: {},
    });
    setIsCreating(true);
  };

  const handleSaveBlog = (newBlog) => {
    if (currentBlog) {
      const updatedBlogs = blogs.map((b) =>
        b.blogUrl === currentBlog.blogUrl ? newBlog : b
      );
      setBlogs(updatedBlogs);
    } else {
      setBlogs([...blogs, newBlog]);
    }
    setIsCreating(false);
  };

  const handleViewComments = (comments) => {
    setSelectedComments(comments); // Open popup with comments
  };

  const filteredBlogs = blogs.filter((blog) =>
    blog.blogName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.blogCategories.some((category) =>
      category.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  

  const handleDeleteBlog = async(blog) =>{
        try{
            const response = await deleteBlog(blog._id, password);
            if (response.message){
                alert(response.message);
                fetchBlogs();
            }
            else{
                alert(response.error || 'Something went wrong!');
            }
        }catch{
            console.log('Something went wrong!');
        }
  }

  return (
    <div className="blog-manager-container">
      {passwordPop && (
        <div className="password-popup">
          <h2>Enter Master Password</h2>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="Master Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Submit</button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
      {!isCreating ? (
        <>
          <h1>Blog Manager</h1>
          <div className="top-bar">
            <button className="create-new-btn" onClick={handleCreateNew}>
              Create New Blog
            </button>
            <input
              type="text"
              placeholder="Search blogs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="blogs-list">
            {filteredBlogs.map((blog) => (
              <div key={blog.blogUrl} className="blog-item">
                <h2>{blog.blogName}</h2>
                {/*<p dangerouslySetInnerHTML={{ __html: blog.blogContent.substring(0,100)+"<b>...Read more</b>" }}></p>*/}
                <img
                  src={blog.images[0]}
                  alt={blog.blogName}
                  className="blog-image"
                />
                <div className="actions">
                  <button onClick={() => handleEdit(blog)}>Edit</button>
                  <button onClick={() => handleViewComments(blog.comments1)}>
                    View Comments
                  </button>
                  <button onClick={() => handleDeleteBlog(blog)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
          {selectedComments && (
            <CommentsPopup
              comments={selectedComments}
              onClose={() => setSelectedComments(null)}
            />
          )}
        </>
      ) : (
        <BlogEditor
          blog={currentBlog}
          onSave={handleSaveBlog}
          onCancel={() => {setIsCreating(false); fetchBlogs()}}
          password = {password}
        />
      )}
    </div>
  );
};

export default Blogs;
