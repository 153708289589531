import config from "../utils/ApiUrl";
const API_URL = config.API_URL;


const postDetailsService = {
  getpostDetails: async () => {
    try {
      const response = await fetch(`${API_URL}/postDetails`);
      const data = await response.json();
      console.log(data);
      return data.types;
    } catch (error) {
      console.error('Failed to fetch fields:', error.message);
      throw new Error('Failed to fetch fields');
    }
  },

  updatepostDetails: async (id, updatedField) => {
    try {
      const response = await fetch(`${API_URL}/postDetails/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedField),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to update field:', error.message);
      throw new Error('Failed to update field');
    }
  },

  deletepostDetails: async (id) => {
    try {
      await fetch(`${API_URL}/postDetails/${id}`, {
        method: 'DELETE',
      });
    } catch (error) {
      console.error('Failed to delete field:', error.message);
      throw new Error('Failed to delete field');
    }
  },

  addpostDetails: async (newField) => {
    try {
      const response = await fetch(`${API_URL}/postDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newField),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to add field:', error.message);
      throw new Error('Failed to add field');
    }
  },
};

export default postDetailsService;
