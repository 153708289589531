import React, { useEffect, useState } from "react";
import getAdminFieldsService from "../services/adminFields";
import restrictedFieldsService from "../services/restrictedFields";

const RestrictedFields = () => {
  const [passwordPop, setPasswordPop] = useState(true);
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [fields, setFields] = useState();
  const [fieldName, setFieldName] = useState("");
  const [restrictedFields, setRestrictedFields] = useState();

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const enteredPassword = password;
    setPasswordPop(false);
    fetchFields(enteredPassword);
  };

  const fetchFields = async (enteredPassword) => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      const restrictedFieldsResponse = await restrictedFieldsService.getFields();
      setFields(fields);
      setRestrictedFields(
        restrictedFieldsResponse.fields ? restrictedFieldsResponse.fields : []
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async () => {
    if (fieldName) {
      // Check if the fieldName already exists in restrictedFields
      const isDuplicate = restrictedFields.some(field => field.name === fieldName);
  
      if (isDuplicate) {
        alert("Already added");
        return; // Exit the function early if a duplicate is found
      }
  
      try {
        const newField = {
          name: fieldName,
        };
        await restrictedFieldsService.addField({ newField, password });
        fetchFields(password); // Refresh the fields
      } catch (error) {
        console.log(error);
        alert("Something went wrong while adding the field.");
      }
    }  
  };

  const handleDelete = async (id) => {
    try {
      await restrictedFieldsService.deleteField({ id, password });
      fetchFields(password);
    } catch (error) {
      console.log(error);
      alert("something went wrong while deleting", error);
    }
  };

  return (
    <div>
      {!passwordPop ? (
        <>
          <div>
            <label>Select the field to be restricted :</label>
            <select onChange={(e) => setFieldName(e.target.value)}>
              <option value={null}>Select</option>
              {fields &&
                fields.map((field) => (
                  <option key={field.name} value={field.name || ""}>
                    {field.name || ""}
                  </option>
                ))}
            </select>
            <button onClick={() => handleAdd()}>Make it for sellers only.</button>
          </div>
          {restrictedFields && (
            <div>
              {restrictedFields.map((field) => (
                <div key={field._id}>
                  <p>
                    {field.name}{" "}
                    <button onClick={() => handleDelete(field._id)}>Delete</button>
                  </p>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="password-popup">
          <h2>Enter Master Password</h2>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              placeholder="Master Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Submit</button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default RestrictedFields;
