import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getAdminFieldsService from "../services/adminFields";
import postDetailsService from "../services/postDetails";
import postDetailsProService from "../services/postDetailsPro";
import getTypesService from "../services/getTypes";

const AdminDashboard = () => {
  const [types, setTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldCategory, setFieldCategory] = useState([]);
  const [fieldFormat, setFieldFormat] = useState("text");
  const [dropdownOptions, setDropdownOptions] = useState("");
  const [fieldIsCascade, setFieldIsCascade] = useState(false);
  const [fieldDependent, setFieldDependent] = useState(null);
  const [fieldDependentValue, setFieldDependentValue] = useState();
  const [editingFieldId, setEditingFieldId] = useState(null);
  const [updatedFieldName, setUpdatedFieldName] = useState("");
  const [updatedFieldCategory, setUpdatedFieldCategory] = useState([]);
  const [updatedFieldFormat, setUpdatedFieldFormat] = useState("");
  const [updatedFieldDropdownOptions, setUpdatedFieldDropdownOptions] =
    useState("");
  const [updatedFieldCascade, setUpdatedFieldCascade] = useState();
  const [updatedFieldDependent, setUpdatedFieldDependent] = useState();
  const [updatedFieldDependentValue, setUpdatedFieldDependentValue] = useState();
  const [newFieldName, setNewFieldName] = useState("");
  const [postDetailsFields, setPostDetailsFields] = useState([]);
  const [editingPostDetailsFieldId, setEditingPostDetailsFieldId] =
    useState(null);
  const [updatedPostDetailsFieldName, setUpdatedPostDetailsFieldName] =
    useState("");
  const [newPostDetailsFieldName, setNewPostDetailsFieldName] = useState("");
  const [postDetailsProFields, setPostDetailsProFields] = useState([]);
  const [editingPostDetailsProFieldId, setEditingPostDetailsProFieldId] =
    useState(null);
  const [updatedPostDetailsProFieldName, setUpdatedPostDetailsProFieldName] =
    useState("");
  const [newPostDetailsProFieldName, setNewPostDetailsProFieldName] =
    useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchFields();
    fetchTypes();
    fetchPostDetailsFields();
    fetchPostDetailsProFields();
  }, []);

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFields = async () => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      setFields(fields);
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const fetchPostDetailsFields = async () => {
    try {
      const postDetailsFields = await postDetailsService.getpostDetails();
      setPostDetailsFields(postDetailsFields);
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const fetchPostDetailsProFields = async () => {
    try {
      const postDetailsProFields =
        await postDetailsProService.getpostDetailsPro();
      setPostDetailsProFields(postDetailsProFields);
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const handleUpdateField = async (id) => {
    try {
      const updatedField = {
        name: updatedFieldName,
        category: updatedFieldCategory,
        format: updatedFieldFormat,
        options: updatedFieldDropdownOptions,
      };
      await getAdminFieldsService.updateAdminField(id, updatedField);
      fetchFields();
      setEditingFieldId(null);
      setUpdatedFieldName("");
      setUpdatedFieldCategory([]);
    } catch (error) {
      console.error("Failed to update field:", error.message);
    }
  };

  const handlePostDetailsUpdateField = async (id) => {
    try {
      const PostDetailsUpdatedField = { name: updatedPostDetailsFieldName };
      await postDetailsService.updatepostDetails(id, PostDetailsUpdatedField);
      fetchPostDetailsFields();
      setEditingPostDetailsFieldId(null);
      setUpdatedPostDetailsFieldName("");
    } catch (error) {
      console.error("Failed to update field:", error.message);
    }
  };

  const handlePostDetailsProUpdateField = async (id) => {
    try {
      const PostDetailsProUpdatedField = {
        name: updatedPostDetailsProFieldName,
      };
      await postDetailsProService.updatepostDetailsPro(
        id,
        PostDetailsProUpdatedField
      );
      fetchPostDetailsProFields();
      setEditingPostDetailsProFieldId(null);
      setUpdatedPostDetailsProFieldName("");
    } catch (error) {
      console.error("Failed to update field:", error.message);
    }
  };

  const handleDeleteField = async (id, position) => {
    try {
      await getAdminFieldsService.deleteAdminField(id,position);
      fetchFields();
    } catch (error) {
      console.error("Failed to delete field:", error.message);
    }
  };

  const handlePostDetailsDeleteField = async (id) => {
    try {
      await postDetailsService.deletepostDetails(id);
      fetchPostDetailsFields();
    } catch (error) {
      console.error("Failed to delete field:", error.message);
    }
  };

  const handlePostDetailsProDeleteField = async (id) => {
    try {
      await postDetailsProService.deletepostDetailsPro(id);
      fetchPostDetailsProFields();
    } catch (error) {
      console.error("Failed to delete field:", error.message);
    }
  };

  const handleAddField = async () => {
    try {
      const newField = {
        name: newFieldName,
        category: fieldCategory,
        format: fieldFormat || "text",
        options: dropdownOptions,
        depend: fieldIsCascade ? [fieldDependent.name, fieldDependentValue] : false,
      };
      await getAdminFieldsService.addAdminField(newField);
      fetchFields();
      setNewFieldName("");
      setFieldCategory([]);
      setFieldFormat("");
      setDropdownOptions("");
      window.location.reload();
    } catch (error) {
      console.error("Failed to add field:", error.message);
    }
  };

  const handlePostDetailsAddField = async () => {
    try {
      const newPostDetailsField = { name: newPostDetailsFieldName };
      await postDetailsService.addpostDetails(newPostDetailsField);
      fetchPostDetailsFields();
      setNewPostDetailsFieldName("");
    } catch (error) {
      console.error("Failed to add field:", error.message);
    }
  };

  const handlePostDetailsProAddField = async () => {
    try {
      const newPostDetailsProField = { name: newPostDetailsProFieldName };
      await postDetailsProService.addpostDetailsPro(newPostDetailsProField);
      fetchPostDetailsProFields();
      setNewPostDetailsProFieldName("");
    } catch (error) {
      console.error("Failed to add field:", error.message);
    }
  };

  const startEditing = (field) => {
    setEditingFieldId(field._id);
    setUpdatedFieldName(field.name);
    setUpdatedFieldCategory(field.category || []);
    setUpdatedFieldFormat(field.format);
    setUpdatedFieldDropdownOptions(field.options);
  };

  const startPostDetailsEditing = (field) => {
    setEditingPostDetailsFieldId(field._id);
    setUpdatedPostDetailsFieldName(field.name);
  };

  const startPostDetailsProEditing = (field) => {
    setEditingPostDetailsProFieldId(field._id);
    setUpdatedPostDetailsProFieldName(field.name);
  };

  const handleCategoryChange = (category, setter) => {
    setter((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((item) => item !== category)
        : [...prevCategories, category]
    );
  };

  return (
    <div className="admin-dashboard-container">
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/contact-message")}
      >
        Contact Messages
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/support-message")}
      >
        Support Messages
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/discounts")}
      >
        Discounts
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/chats-inspect")}
      >
        Inspect Chats
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/users-inspect")}
      >
        Inspect Users
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate("/sellers-verify")}
      >
        Inspect Sellers Verify Applications
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate('/indexing')}
      >
        Adjust Field-positions
      </button>
      <button
        className="admin-dashboard-button"
        onClick={() => navigate('/sms-sender')}
      >
        Send Sms to users
      </button>

      <button
        className="admin-dashboard-button"
        onClick={() => navigate('/restrict-fields')}
      >
        Sellers only- fields
      </button>

      <h1 className="admin-dashboard-title">New Post Fields Control</h1>

      <div className="admin-dashboard-add-field-container">
        <label>New Field Name</label>
        <input
          type="text"
          className="admin-dashboard-input"
          value={newFieldName}
          onChange={(e) => setNewFieldName(e.target.value)}
          placeholder="New field name"
        />
        <label>Field Category</label>
        <div className="admin-dashboard-checkbox-group">
          {types &&
            types.map((type) => (
              <div key={type} className="admin-dashboard-checkbox">
                <input
                  type="checkbox"
                  id={`add-category-${type}`}
                  value={type}
                  checked={fieldCategory.includes(type)}
                  onChange={() => handleCategoryChange(type, setFieldCategory)}
                />
                <label htmlFor={`add-category-${type}`}>{type}</label>
              </div>
            ))}
        </div>

        <label>Select Format</label>
        <select
          className="admin-dashboard-select"
          value={fieldFormat}
          onChange={(e) => setFieldFormat(e.target.value)}
        >
          <option value="text">text</option>
          <option value="dropdown">dropdown</option>
          <option value="date">date</option>
        </select>

        {fieldFormat === "dropdown" && (
          <div>
            <label>Dropdown Options</label>
            <input
              type="text"
              className="admin-dashboard-input"
              value={dropdownOptions}
              onChange={(e) => setDropdownOptions(e.target.value)}
              placeholder="Comma-separated options"
            />
          </div>
        )}
        <label>Cascade?</label>
        <select
          className="admin-dashboard-select"
          value={fieldIsCascade}
          onChange={(e) => {
            setFieldIsCascade(e.target.value === "true");
            console.log(e.target.value, fieldIsCascade);
          }}
        >
          <option value={false}>False</option>
          <option value={true}>True</option>
        </select>

        {fieldIsCascade && (
          <div>
            <select
              className="admin-dashboard-input"
              value={fieldDependent ? fieldDependent.name : ""}
              onChange={(e) => {
                const selectedField = fields.find(
                  (field) => field.name === e.target.value
                );
                setFieldDependent(selectedField);
                console.log(selectedField._id, selectedField.name);
              }}
            >
              <option value={null}>Select</option>
              {fields &&
                fields.map((field) => (
                  <option key={field.name} value={field.name}>
                    {field.name}
                  </option>
                ))}
            </select>
          </div>
        )}

        {fieldDependent && fieldIsCascade &&
          (fieldDependent.format === "dropdown" ? (
            <select onChange={(e) => setFieldDependentValue(e.target.value)}>
              <option value={null}>Select</option>
              {fieldDependent.options?.split(",").map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <select onChange={(e) => setFieldDependentValue(e.target.value)}>
              <option value={null}>Select</option>
              <option value={"X---Any_Value---X"}>Any Value</option>
            </select>
          ))}

        <button className="admin-dashboard-button" onClick={handleAddField}>
          Add New Field
        </button>
      </div>

      <div className="admin-dashboard-field-list">
        <h1 className="admin-dashboard-title">Fields</h1>
        <table className="admin-dashboard-table">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Category</th>
              <th>Format</th>
              <th>Dropdown Options</th>
              <th>Cascade?</th>
              <th>Depend field</th>
              <th>Depend value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fields &&
              fields.map((field) => (
                <tr key={field._id}>
                  <td>
                    {editingFieldId === field._id ? (
                      <input
                        type="text"
                        className="admin-dashboard-input"
                        value={updatedFieldName}
                        onChange={(e) => setUpdatedFieldName(e.target.value)}
                      />
                    ) : (
                      field.name
                    )}
                  </td>
                  <td>
                    {editingFieldId === field._id ? (
                      <div className="admin-dashboard-checkbox-group">
                        {types &&
                          types.map((type) => (
                            <div
                              key={type}
                              className="admin-dashboard-checkbox"
                            >
                              <input
                                type="checkbox"
                                id={`edit-category-${type}`}
                                value={type}
                                checked={updatedFieldCategory.includes(type)}
                                onChange={() =>
                                  handleCategoryChange(
                                    type,
                                    setUpdatedFieldCategory
                                  )
                                }
                              />
                              <label htmlFor={`edit-category-${type}`}>
                                {type}
                              </label>
                            </div>
                          ))}
                      </div>
                    ) : (
                      field.category.join(", ")
                    )}
                  </td>
                  <td>
                    {editingFieldId === field._id ? (
                      <select
                        className="admin-dashboard-select"
                        value={updatedFieldFormat}
                        onChange={(e) => setUpdatedFieldFormat(e.target.value)}
                      >
                        <option value="text">Text</option>
                        <option value="dropdown">Dropdown</option>
                        <option value="date">Date</option>
                      </select>
                    ) : (
                      field.format
                    )}
                  </td>
                  <td>
                    {editingFieldId === field._id &&
                    updatedFieldFormat === "dropdown" ? (
                      <input
                        type="text"
                        className="admin-dashboard-input"
                        value={updatedFieldDropdownOptions}
                        onChange={(e) =>
                          setUpdatedFieldDropdownOptions(e.target.value)
                        }
                      />
                    ) : (
                      field.options
                    )}
                  </td>
                  <td>
                  {editingFieldId === field._id && 
                    field.depend !== undefined ? (
                      <select
                        onChange={(e) =>
                          setUpdatedFieldCascade(e.target.value)
                        }
                      >
                        <option value={false}>Select</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    ) : (
                      "False"
                    )}
                  </td>
                  <td>
                  {editingFieldId === field._id && field.depend !== undefined &&
                    field.depend ? (
                      <select
                      onChange={(e)=>setFieldDependent(e.target.value)}
                      >
                      <option value={null}>Select</option>
                      {fields.map((fieldOpt)=>(
                        <option key={fieldOpt.name} value={fieldOpt}>{fieldOpt.name}</option>
                      ))}
                      </select>
                    ) : (
                      field.depend ? field.depend[0] : "-"
                    )}
                  </td>
                  <td>
                  {/*editingFieldId === field._id && field.depend !== undefined &&
                    field.depend ? (
                      updatedFieldDependent ? (
                        updatedFieldDependent.format === "dropdown" ? (
                          <select>
                           updatedFieldDependent.options.split(",").map((valueOpt)=>(
                              <option value={valueOpt} key={valueOpt}>{valueOpt}</option>
                            
                          ))
                          </select>
                        ):
                        <select>
                          <option>HIHIPH</option>
                        </select>
                      )
                    ) : (
                      field.depend ? field.depend[1] : "-"
                    )*/}
                  </td>
                  
                  <td>
                    {editingFieldId === field._id ? (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() => handleUpdateField(field._id)}
                        >
                          Save
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() => setEditingFieldId(null)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() => startEditing(field)}
                        >
                          Edit
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() => handleDeleteField(field._id, fields.indexOf(field))}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="admin-dashboard-field-list">
        <h1 className="admin-dashboard-title">Post Details Fields</h1>
        <div className="admin-dashboard-add-field-container">
          <label>New Field Name</label>
          <input
            type="text"
            className="admin-dashboard-input"
            value={newPostDetailsFieldName}
            onChange={(e) => setNewPostDetailsFieldName(e.target.value)}
            placeholder="New field name"
          />
          <button
            className="admin-dashboard-button"
            onClick={handlePostDetailsAddField}
          >
            Add New Field
          </button>
        </div>
        <table className="admin-dashboard-table">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {postDetailsFields &&
              postDetailsFields.map((field) => (
                <tr key={field._id}>
                  <td>
                    {editingPostDetailsFieldId === field._id ? (
                      <input
                        type="text"
                        className="admin-dashboard-input"
                        value={updatedPostDetailsFieldName}
                        onChange={(e) =>
                          setUpdatedPostDetailsFieldName(e.target.value)
                        }
                      />
                    ) : (
                      field.name
                    )}
                  </td>
                  <td>
                    {editingPostDetailsFieldId === field._id ? (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() =>
                            handlePostDetailsUpdateField(field._id)
                          }
                        >
                          Save
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() => setEditingPostDetailsFieldId(null)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() => startPostDetailsEditing(field)}
                        >
                          Edit
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() =>
                            handlePostDetailsDeleteField(field._id)
                          }
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="admin-dashboard-field-list">
        <h1 className="admin-dashboard-title">Post Details Pro Fields</h1>
        <div className="admin-dashboard-add-field-container">
          <label>New Field Name</label>
          <input
            type="text"
            className="admin-dashboard-input"
            value={newPostDetailsProFieldName}
            onChange={(e) => setNewPostDetailsProFieldName(e.target.value)}
            placeholder="New field name"
          />
          <button
            className="admin-dashboard-button"
            onClick={handlePostDetailsProAddField}
          >
            Add New Field
          </button>
        </div>
        <table className="admin-dashboard-table">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {postDetailsProFields &&
              postDetailsProFields.map((field) => (
                <tr key={field._id}>
                  <td>
                    {editingPostDetailsProFieldId === field._id ? (
                      <input
                        type="text"
                        className="admin-dashboard-input"
                        value={updatedPostDetailsProFieldName}
                        onChange={(e) =>
                          setUpdatedPostDetailsProFieldName(e.target.value)
                        }
                      />
                    ) : (
                      field.name
                    )}
                  </td>
                  <td>
                    {editingPostDetailsProFieldId === field._id ? (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() =>
                            handlePostDetailsProUpdateField(field._id)
                          }
                        >
                          Save
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() => setEditingPostDetailsProFieldId(null)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="admin-dashboard-button"
                          onClick={() => startPostDetailsProEditing(field)}
                        >
                          Edit
                        </button>
                        <button
                          className="admin-dashboard-button admin-dashboard-button-delete"
                          onClick={() =>
                            handlePostDetailsProDeleteField(field._id)
                          }
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
